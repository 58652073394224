<template>
  <main>
    <aside class="details">
      <h2>Cable details</h2>

      <dl>
        <template v-if="cableId">
          <dt>Cable code:</dt>
          <dd>{{cableId}}</dd>
        </template>
        <template v-if="cableVersion">
          <dt>Firmware version:</dt>
          <dd>{{cableVersion}}</dd>
        </template>
        <dt>Ownership status:</dt>
        <dd class="link">
          <span>{{isLinked? 'Linked to user' : 'Unlinked'}}</span>
          <button v-if="isLinked" @click="unlink" :disabled="isPlugged || isCharging || shouldBeCharging">Unlink</button>
        </dd>
        <dt>Connection status:</dt>
        <dd class="status">
          <span :class="status">{{status | capitalize}}</span>
          <template v-if="lastOnlineDatetime">
            for <time :datetime="lastOnlineDatetime">{{lastOnlinePeriod}}</time>
          </template>
        </dd>
        <template v-if="isConnected">
          <dt>Plugged into car:</dt>
          <dd><span :class="plugged">{{plugged | capitalize}}</span></dd>
        </template>
        <template v-if="isPlugged && isConnected">
          <dt>Active charging limit:</dt>
          <dd class="charging">
            <span>{{chargeLimit}}<small>A</small></span>
            <button @click="forceCharging" :disabled="isCharging !== shouldBeCharging">{{isCharging ? 'Stop charging' : 'Charge now'}}</button>
          </dd>
        </template>
      </dl>
    </aside>

    <h2>Max power</h2>

    <div class="range">
      <span class="min power">{{minPower}}<small>A</small></span>
      <span class="slider">
        <span class="power indicator current" :style="{left: currentIndicatorOffset}">{{currentPower}}<small>A</small></span>
        <span class="power indicator new" :style="{left: newIndicatorOffset}">{{power}}<small>A</small></span>
        <input type="range" v-model="power" :min="minPower" :max="maxPower" step="1" />
      </span>
      <span class="max power">{{maxPower}}<small>A</small></span>
    </div>
    
    <div class="actions">
      <button v-if="stage < 2" class="action" :disabled="alreadySent" @click="update">Override</button>
      <p class="updating" v-if="stage === 2">Please powercycle the cable and wait a minute to apply the new settings&hellip;</p>
      <p class="complete" v-if="stage === 3">New settings applied!</p>
    </div>
  </main>
</template>

<script>
import { parse, formatDistanceToNow, formatISO } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

const UNDEFINED = -1
const INITIAL = 0
const CHANGED = 1
const SENT = 2
const RECEIVED = 3

export default {
  data () {
    return {
      power: 8,
      currentPower: 8,
      stage: UNDEFINED,
      shouldBeCharging: null,
      overridePolling: null,
      statusPolling: null,
    }
  },
  created () {
    if (this.cable == null) {
      this.$router.replace('/search/')
    }
    else {
      this.power = this.cable.maxPower
      this.currentPower = this.cable.confirmedMaxPower

      if (this.power !== this.currentPower) {
        this.stage = SENT
        this.startOverridePolling()
      }
      else {
        this.stage = INITIAL
      }

      this.startStatusPolling()
    }
  },
  beforeDestroy () {
    this.stopOverridePolling()
    this.stopStatusPolling()
  },
  computed: {
    ...mapGetters('Cable', ['cable', 'isConnected', 'isLinked', 'lastOnline', 'isPlugged', 'chargeLimit']),
    isCharging () { return this.chargeLimit > 0 },
    status () { return this.isConnected ? 'online' : 'offline' },
    plugged () { return this.isPlugged ? 'yes' : 'no' },
    lastOnlineTime () { return parse(this.lastOnline, 'T', new Date()) },
    lastOnlinePeriod () { return this.lastOnline ? formatDistanceToNow(this.lastOnlineTime) : null },
    lastOnlineDatetime () { return this.lastOnline ? formatISO(this.lastOnlineTime) : null },
    cableId () { return this.cable != null ? this.cable.id : '' },
    cableVersion () { return this.cable != null ? this.cable.version : '' },
    minPower () { return 0 },
    maxPower () { return 32 },
    newIndicatorOffset () {
      return this.indicatorOffset(this.power)
    },
    currentIndicatorOffset () {
      return this.indicatorOffset(this.currentPower)
    },
    alreadySent () {
      return this.cable != undefined && Math.round(this.cable.maxPower) === Math.round(this.power)
    }
  },
  watch: {
    power (now, then) {
      if (this.alreadySent && this.cable.maxPower !== this.cable.confirmedMaxPower) {
        this.stage = SENT
      }
      else if (this.power !== this.cable.maxPower) {
        this.stage = CHANGED
      }
    }
  },
  methods: {
    ...mapActions('Cable', ['getStatus', 'findCable', 'updateMaxPower', 'startCharging', 'stopCharging', 'unlinkCharger']),
    update () {
      clearInterval(this.overridePolling)

      this.stage = SENT
      this.updateMaxPower(this.power)
      this.startOverridePolling()
    },
    forceCharging () {
      this.shouldBeCharging = this.chargeLimit > 0 ? false : true
      this.chargeLimit > 0 ? this.stopCharging(this.cableId) : this.startCharging(this.cableId)
    },
    startOverridePolling () {
      this.overridePolling = setInterval(() => {
        this.findCable(this.cable.id)

        if (this.cable.confirmedMaxPower == this.cable.maxPower) {
          this.stage = RECEIVED
          clearInterval(this.overridePolling)
        }
      }, 5000)
    },
    stopOverridePolling () {
      if (this.overridePolling) {
        clearInterval(this.overridePolling)
      }
    },
    startStatusPolling () {
      this.getStatus(this.cable.id).then(() => {
        this.shouldBeCharging = this.chargeLimit > 0
      })
      

      this.statusPolling = setInterval(() => {
        this.getStatus(this.cable.id)
      }, 5000)
    },
    stopStatusPolling () {
      if (this.statusPolling) {
        clearInterval(this.statusPolling)
      }
    },
    unlink () {
      if (confirm('Unlink this cable? This will unlink this cable from the user account. It cannot be reverted!')) {
        this.unlinkCharger(this.cableId)
      }
    },
    indicatorOffset (power) {
      const offset = (power - this.minPower) / (this.maxPower - this.minPower);
      const percents = (offset * 100) + '%';
      const pixels = (0.5 - offset) * 28 + 'px';

      return `calc(${percents} + ${pixels})`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

h2 {
  text-align: center;
  margin-top: ($body-padding / 2);
  margin-bottom: ($body-padding / 2);

  @include icon-left(6, 1);

  &::before {
    display: inline-block;
    position: static;
    vertical-align: -30%;
    margin-right: 0px;
    margin-left: -32px;
  }
}

.details {
  margin-bottom: $body-padding * 1.5;

  h2 {
    @include icon-offset(6, 2);
  }
}

dl {
  dt {
    font-size: 0.75rem;
  }
  dd {
    margin-left: 0;
    margin-bottom: ($body-padding / 2);
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

dl, .range {
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
  background: $default-background;
  border-radius: 3px;
}

.status {
  span {
    &::before {
      display: inline-block;
      border-radius: 4px;
      width: 8px;
      height: 8px;
      margin-right: 4px;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
      background: green;
      vertical-align: 8%;
      content: ''
    }

    &.online::before {
      background: green;
    }

    &.offline::before {
      background: grey;
    }
  }
}

.charging, .link {
  position: relative;

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 7px;
    border-radius: 2px;
    background: $primary-color;
    border-color: $primary-color;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 500;

    &:disabled {
      cursor: default;
      background: $inactive-color;
      border-color: $inactive-color;
    }
  }
}

.range {
  display: flex;
  align-items: center;
  margin-top: 20px;

  $spacing: ($body-padding / 4);

  .slider {
    position: relative;
    flex: 1 0 auto;

    input {
      width: 100%;
    }

    .indicator {
      pointer-events: none;

      position: absolute;
      top: -34px;
      width: 30px;
      padding: 4px 6px;
      margin-left: -21px;
      border-radius: 3px;
      background: $primary-color;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
      text-align: center;
      color: #fff;

      &::before, &:after {
        position: absolute;
        bottom: -4px;
        left: 50%;
        margin-left: -4px;
        border-top: 4px solid $primary-color;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: '';
      }
      &:before {
        bottom: -5px;
        border-top-color: rgba(0, 0, 0, 0.15);
      }
      &.current {
        background: $default-text;

        &::after {
          border-top-color: $default-text;
        }
      }
    }
  }

  .min {
    margin-right: $spacing;
  }
  .max {
    margin-left: $spacing;
  }
}

.actions {
  margin-top: $body-padding;
  text-align: center;

  p {
    margin-left: 24px;
    margin-right: 24px;
    padding: 10px 20px;
    background: $default-background;
    border-radius: 1px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);

    &.complete {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &.complete::before {
      display: inline-block;
      width: 28px;
      height: 28px;
      background: url(/images/icons.png) -194px -2px no-repeat;
      background-size: $icon-sheet-width $icon-sheet-height;
      content: '';
      vertical-align: -40%;
      margin-right: 5px;
    }
  }
}

.power {
  font-weight: 500;

  small {
    font-weight: 700;
  }
}

// 
// RANGE Input Styles: http://danielstern.ca/range.css/?ref=css-tricks#/
// 
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 10px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #ddd;
  background-image: repeating-linear-gradient(90deg, #ddd, #ddd calc(5.88% - 1px), #aaa calc(5.88% - 1px), #aaa 5.88%);
  background-position: 11px 0px;
  border-radius: 3px;
  border: 1px solid rgba(1, 1, 1, 0.16);
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1.5px rgba(0, 0, 49, 0.1), 0px 0px 1px rgba(0, 0, 75, 0.1);
  border: 1px solid rgba(0, 0, 3, 0.67);
  height: 26px;
  width: 26px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #dfdfdf;
  background-image: repeating-linear-gradient(90deg, #ddd, #ddd calc(5.88% - 1px), #aaa calc(5.88% - 1px), #aaa 5.88%);
  background-position: 11px 0px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #ddd;
  background-image: repeating-linear-gradient(90deg, #ddd, #ddd calc(5.88% - 1px), #aaa calc(5.88% - 1px), #aaa 5.88%);
  background-position: 11px 0px;
  border-radius: 3px;
  border: 1px solid rgba(1, 1, 1, 0.16);
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1.5px rgba(0, 0, 49, 0.1), 0px 0px 1px rgba(0, 0, 75, 0.1);
  border: 1px solid rgba(0, 0, 3, 0.67);
  height: 26px;
  width: 26px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #c5c5c5;
  border: 1px solid rgba(1, 1, 1, 0.16);
  border-radius: 6px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type=range]::-ms-fill-upper {
  background: #ddd;
  border: 1px solid rgba(1, 1, 1, 0.16);
  border-radius: 6px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1.5px rgba(0, 0, 49, 0.1), 0px 0px 1px rgba(0, 0, 75, 0.1);
  border: 1px solid rgba(0, 0, 3, 0.67);
  height: 26px;
  width: 26px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
  height: 6px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #ddd;
}
input[type=range]:focus::-ms-fill-upper {
  background: #dfdfdf;
}

</style>