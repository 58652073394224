<template>
  <nav>
    <h1 id="logo">Ohme charger installation</h1>
    <button v-if="hasBack" class="back" @click="back">Search</button>
    <button class="logout" @click="logout">Logout</button>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

import * as firebase from 'firebase/app'
import 'firebase/auth'

export default {
  computed: {
    hasBack () {
      return this.$route.path !== '/search'
    }
  },
  methods: {
    logout () {
      firebase.auth().signOut()
      this.$router.replace('/authentication')
    },
    back () {
      this.$router.replace('/search')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

nav {
  position: relative;
  padding: ($body-padding / 2);
  background: $default-background;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  h1 {
    overflow: hidden;
    text-indent: -999em;
    width: 98px;
    height: 30px;
    padding-top: ($body-padding * 0.5);
    margin: 0 auto;
    background: url(/images/logo.png) no-repeat 50% 50%;
    background-size: 98px 30px;
  }

  button {
    position: absolute;
    top: ($body-padding / 3 * 2);
    padding: ($body-padding / 4) ($body-padding / 2);
    border-color: transparent;
    background: transparent;
    border-radius: 0;
    font-weight: 500;
    color: $primary-color;

    &.back {
      left: $body-padding / 2;
    }
    &.logout {
      right: $body-padding / 2;
    }
  }
}

</style>