import Vue from 'vue'
import Router from 'vue-router'

import store from './store'

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'current',
  linkExactActiveClass: 'current-root',
  routes: [
    {
      meta: { title: 'Authentication', requiresAuthentication: false },
      path: '/authentication',
      component: require('sections/Authentication').default
    },
    {
      meta: { title: 'Find cable' },
      path: '/search',
      component: require('sections/Search').default
    },
    {
      meta: { title: 'Cable details' },
      path: '/cable',
      component: require('sections/Cable').default
    },
    {
      path: '*',
      redirect: '/search'
    }
  ]
})

router.beforeEach(function (to, from, next) {
  const isAuthenticated = store.getters['Account/isAuthenticated']

  if (
    to.matched.some(route => route.meta.requiresAuthentication !== false) &&
    !isAuthenticated
  ) {
    next({
      path: '/authentication',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})

router.beforeEach(function (to, from, next) {
  document.title = (to.meta.title || 'Untitled') + ' – Ohme'
  next()
})

export default router