import * as production from '~/config.prod.js'
import * as beta from '~/config.beta.js'
import * as development from '~/config.dev.js'
import * as local from '~/config.local.js'
import * as dataqa from '~/config.dataqa.js'

function getConfig () {
  switch (window.location.hostname) {
    // Local
    case 'localhost':
      return local
    // Dev
    case 'apps-dev.ohme-ev.com':
    case 'api-dev.ohme.io':
    case 'api-dev2.ohme.io':
    case 'api-dev3.ohme.io':
      return development
    // Beta
    case 'api-beta.ohme.io':
      return beta
    // Prod
    case 'api.ohme.io':
      return production
    // Data QA
    case 'api-dataqa.ohme.io':
      return dataqa
    default:
      return development
  }
}

const config = getConfig()

export const api = config.api
export const firebase = config.firebase
