export const api = {
  baseURL: 'http://localhost:8080/v1/',
}

export const firebase = {
  apiKey: 'AIzaSyATNF8MEFJDB6k6iZ-zlfOAEqhUt5kXiCw',
  authDomain: 'ohme-bc550.firebaseapp.com',
  databaseURL: 'https://ohme-bc550.firebaseio.com',
  projectId: 'ohme-bc550',
  storageBucket: 'ohme-bc550.appspot.com',
  messagingSenderId: '948226738903',
  appId: '1:948226738903:web:60cc6d26fc96b8a81bfe92'
}