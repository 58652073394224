import * as firebase from 'firebase/app'
import 'firebase/auth'

import axios from 'axios'
import { api as config } from '~/config'


//
// Setup API + Firebase token authentication
//

const api = axios.create(config)

let AUTH_TOKEN = ''
let AUTH_LAST = 0

const TOKEN_TTL = 60 * 1000 // 60 seconds

function refreshAuthToken () {
  if (AUTH_LAST + TOKEN_TTL > Date.now()) {
    return new Promise(resolve => resolve(AUTH_TOKEN))
  }
  else return firebase.auth().currentUser.getIdToken().then(function (token) {
    AUTH_TOKEN = token
    AUTH_LAST = Date.now()

    // console.log('AUTH_TOKEN', AUTH_TOKEN)
  })
}

api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Firebase ${AUTH_TOKEN}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

function promiseToGet (endpoint, success, failure) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api.get(endpoint).then(function ({ data }) {
        success(data, resolve)
      }).catch(failure === undefined ? reject : failure)
    })
  })
}

// 
// Parse data objects
// 

function parseCable (data) {
  return {
    id: data.id,
    version: data.firmwareVersion,
    maxPower: data.maxAmpOverride == null ? 32 : data.maxAmpOverride,
    confirmedMaxPower: data.confirmedMaxAmpOverride == null ? 32 : data.confirmedMaxAmpOverride
  }
}


// 
// API endpoints
// 

export function getCableById (cableId) {
  return promiseToGet(`/chargeDevices/${cableId}`, function (data, resolve) {
    resolve(parseCable(data))
  })
}

export function setMaxPower (cableId, maxPower) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api.post(`/chargeDevices/${cableId}/maxAmp?maxAmp=${maxPower}`).then(function({ data }) {
        resolve(parseCable(data))
      }).catch(reject)
    })
  })
}

export function getCableStatus (cableId) {
  return promiseToGet(`/chargeDevices/${cableId}/status`, function (data, resolve) {
    resolve({
      connected: !!data.online,
      lastOnline: data.lastConnectDisconnect,
      linked: !!data.linkedToUser,
      plugged: !!data.pluggedIn,
      chargeLimit: numericalValue(data.currentLimitAmps),
    })
  })
}

export function startCharging (cableId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api.post(`/chargeDevices/${cableId}/forceStartCharge`).then(function({ data }) {
        resolve({})
      }).catch(reject)
    })
  })
}

export function stopCharging (cableId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api.post(`/chargeDevices/${cableId}/forceStopCharge`).then(function({ data }) {
        resolve({})
      }).catch(reject)
    })
  })
}

export function unlinkCharger (cableId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api.post(`/chargeDevices/${cableId}/unlink`).then(function({ data }) {
        resolve(true)
      }).catch(reject)
    })
  })
}


//
// Utilities
//

function nullOrRatio (value) {
  return isNaN(value) || value == null ? null : value / 100
}

function stringValue (value) {
  return value == null? '' : value
}

function numericalValue (value) {
  return isNaN(value) ? 0 : value
}