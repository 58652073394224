import { 
  getCableById,
  setMaxPower,
  getCableStatus,
  startCharging,
  stopCharging,
  unlinkCharger
} from '~/api'

export const state = {
  cable: null, // {id: "", version: "", maxPower: 32, confirmedMaxPower: 32}
  connected: null, // null | true | false
  lastOnline: null, // timestamp
  linked: false, // true | false
  plugged: false, // true | false
  chargeLimit: null, // chargeLimit > 0? Charging : Not charging
  searching: false,
  notFound: false,
}

export const getters = {
  cable: state => state.cable,
  isConnected: state => !!state.connected,
  lastOnline: state => state.lastOnline,
  isLinked: state => !!state.linked,
  isPlugged: state => !!state.plugged,
  chargeLimit: state => state.chargeLimit,
  isSearching: state => !!state.searching,
  isNotFound: state => !!state.notFound,
}

export const mutations = {
  setCable (state, cable) {
    state.cable = cable
  },
  setConnected (state, connected) {
    state.connected = connected
  },
  setLastOnline (state, timestamp) {
    state.lastOnline = timestamp
  },
  setLinked (state, linked) {
    state.linked = linked
  },
  setPlugged (state, plugged) {
    state.plugged = plugged
  },
  setChargeLimit (state, chargeLimit) {
    state.chargeLimit = chargeLimit
  },
  setSearchingStatus (state, searching) {
    state.searching = !!searching
  },
  setNotFoundStatus (state, notFound) {
    state.notFound = !!notFound
  }
}

export const actions = {
  getStatus ({ commit }, cableId) {
    return getCableStatus(cableId).then(function (cable) {
      commit('setConnected', cable.connected)
      commit('setLastOnline', cable.lastOnline)
      commit('setPlugged', cable.plugged)
      commit('setLinked', cable.linked)
      commit('setChargeLimit', cable.chargeLimit)
    })
  },
  findCable ({ commit }, cableId) {
    commit('setNotFoundStatus', false)
    commit('setSearchingStatus', true)
    getCableById(cableId).then(function (cable) {
      if (cable.id == null) {
        commit('setNotFoundStatus', true)
        commit('setCable', null)
      } else {
        commit('setCable', cable)
      }
    }).finally(function () {
      commit('setSearchingStatus', false)
    })
  },
  startCharging ({}, cableId) {
    startCharging(cableId)
  },
  stopCharging ({}, cableId) {
    stopCharging(cableId)
  },
  updateMaxPower ({ state, commit }, power) {
    setMaxPower(state.cable.id, power).then(cable => {
      commit('setCable', cable)
    })
  },
  unlinkCharger ({ commit }, cableId) {
    unlinkCharger(cableId).then(function (unlinked) {
      commit('setLinked', !unlinked)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}