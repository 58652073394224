export const state = {
  initializing: false,
  loading: false,
}

export const getters = {
  isInitialized: state => !state.initializing,
  isLoading: state => state.loading,
}

export const mutations = {
  setInitStatus (state, value) {
    state.initializing = !!value
  },
  setLoadingStatus (state, value) {
    state.loading = !!value
  },
}

export const actions = {
  setup ({ commit }, account) {
    commit('Account/signIn', {
      uid: account.uid,
      displayName: account.displayName,
      email: account.email,
      photoURL: account.photoURL,
    })

    commit('setInitStatus', false)
  },
  teardown ({ commit }) {
    commit('Account/signOut')

    commit('setInitStatus', false)
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}