import Vue from 'vue'

function fraction (value, places = 2) {
  const p = Math.pow(10, places)

  return parseFloat(Math.round(value * p) / p).toFixed(places)
}

function powers (value) {
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

function decimal (value, places) {
  return powers(fraction(value, places))
}

Vue.filter('decimal', decimal)
Vue.filter('currency', decimal)
Vue.filter('percentage', function (value, places = 0) {
  return fraction(value * 100, places)
})
Vue.filter('capitalize', function (value) {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
})


function isEmtpy (value) {
  if (!value) {
    return true
  }
  else if (typeof value === 'string') {
    return value === ''
  }
  else if (typeof value === 'number') {
    return value === 0
  }
  else if (typeof value === 'object') {
    return Object.values(value).reduce(function (r, v) {
      return isEmtpy(v) && r
    }, true)
  }
}

Vue.directive('hide-empty', {
  inserted: function (el, {value}) {

    if (isEmtpy(value)) el.style.display = 'none'
    else el.style.display = null
  }
})
