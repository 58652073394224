<template>
  <main>
    <img src="/images/code-location.png" width="117" alt="">

    <h2><label for="cable-id">Find cable</label></h2>
    <p>Please enter the cable code located under the QR code.</p>
    <form @submit.prevent="search">
      <input required type="text" v-model="cableId" id="cable-id" placeholder="Enter cable code" autocomplete="off">
      <p v-if="isNotFound" class="error">The cable code does not seem to be valid.</p>
      <button class="action" type="submit" :disabled="isSearching">Search</button>
    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      disabled: false,
      cableId: null,
    }
  },
  watch: {
    cable (cable) {
      if (cable != null) {
        this.$router.push('/cable')
      }
    }
  },
  computed: {
    ...mapGetters('Cable', ['isSearching', 'isNotFound', 'cable'])
  },
  methods: {
    ...mapActions('Cable', ['findCable']),
    search () {
      this.findCable(this.cableId)
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  text-align: center;
}
p {
  margin: 10px 0;
}
img {
  margin: 20px auto;
  display: block;
}
input {
  width: 100%;
}
button {
  margin-top: 10px;
}
</style>